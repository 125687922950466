import { useMemo } from "react";
import axiosInstance, { endpoints, fetcher } from "src/utils/axios";
import { errorProcess } from "src/utils/error-process";
import { storage } from 'src/firebase';
import { ref, deleteObject, getDownloadURL, listAll } from "firebase/storage";
import useSWR, { mutate } from "swr";
import { STORAGE_INFOS } from "src/auth/context/auth-provider";


const options = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
};


type resPostProp = {
    loading: boolean,
    error: any,
    isValidating: boolean
}


export function useGetAllMyTemplates(): any {

    const URL = `${endpoints.dashboard.customer.template.getMine}`;

    const user = JSON.parse(localStorage.getItem(STORAGE_INFOS) || '{}');

    const { data, isLoading, error, mutate } = useSWR([URL, user?.id], fetcher, {
        ...options,
        keepPreviousData: false
    });

    const memoizedValue = useMemo(
        () => ({
            cards: data || [],
            Loading: isLoading,
            error,
            mutate
        }),
        [data, error, isLoading, mutate]
    );

    return memoizedValue;
}

export async function deleteImgFromFirebase(id: string, cardType: 'HB' | 'HF' | 'VB' | 'VF') {
    const fileRef = ref(storage, `${process.env.REACT_APP_TEMPLATES_FOLDER}/${id}/${cardType}`);

    const memoizedValue: resPostProp = {
        loading: true,
        error: null,
        isValidating: false
    };

    try {
        // Check if the file exists
        await getDownloadURL(fileRef)
        // If getDownloadURL doesn't throw an error, the file exists
        await deleteObject(fileRef);
        memoizedValue.loading = false;
        memoizedValue.isValidating = true; // Set to true if response data exists
    } catch (error: any) {
        // If getDownloadURL throws an error, the file doesn't exist
        if (error.code === 'storage/object-not-found') {
            memoizedValue.loading = false;
            memoizedValue.error = null; // i set it to null because i wanna delte the file from firebase when it exists
        } else {
            // Handle other errors
            memoizedValue.loading = false;
            memoizedValue.error = errorProcess(error); // Set the error message
        }
    }
    return memoizedValue;
}

export async function deleteTemplateFromFirebase(id: string): Promise<void> {
    try {
        const URL = endpoints.dashboard.admin.template.delete(id);

        await axiosInstance.delete(URL);

        const imagesRef = ref(storage, `${process.env.REACT_APP_TEMPLATES_FOLDER}/${id}`);
        const response = await listAll(imagesRef);

        await Promise.all(response.items.map(async (item) => {
            await deleteObject(item);
        }));
    } catch (error) {
        throw error;
    }
}