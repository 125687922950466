import { useMemo } from "react";
import { STORAGE_INFOS } from "src/auth/context/auth-provider";
import { IUserItem, UserType } from "src/types/users";
import { endpoints, fetcher } from "src/utils/axios";
import useSWR from "swr";



const options = {
    keepPreviousData: true,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
};


export function useGetAllCustomers(pageIndex: number = 0, pageSize: number = 5): {
    customers: UserType[],
    totalCount: number,
    Loading: boolean,
    error: any
} {
    const URL = `${endpoints.dashboard.admin.customers.getAll(pageIndex, pageSize)}`;

    const { data, isLoading, error } = useSWR(URL, fetcher, options);

    const memoizedValue = useMemo(
        () => ({
            customers: data?.customers || [] as UserType[],
            totalCount: data?.totalCount || 0,
            Loading: isLoading,
            error
        }),
        [data?.customers, data?.totalCount, isLoading, error]
    );

    return memoizedValue;
}

export function useGetCustomer(): {
    customer: IUserItem,
    Loading: boolean,
    mutate: () => void,
    error: any
} {
    const URL = `${endpoints.dashboard.customer.account.getMyInfos}`;

    const { data, isLoading, error, mutate } = useSWR(URL, fetcher, options);

    const memoizedValue = useMemo(
        () => ({
            customer: data || {} as IUserItem,
            Loading: isLoading,
            mutate,
            error
        }),
        [data, isLoading, error, mutate]
    );

    return memoizedValue;
}

export function useGetCustomerById(id?: string): {
    customer: IUserItem,
    Loading: boolean,
    mutate: () => void,
    error: any
} {
    const URL = id && `${endpoints.dashboard.admin.customers.getOne(id)}`
    const { data, isLoading, error, mutate } = useSWR(id ? [URL, id] : null, fetcher, options);
    const memoizedValue = useMemo(
        () => ({
            customer: data || {} as IUserItem,
            Loading: isLoading,
            mutate,
            error
        }),
        [data, isLoading, error, mutate]
    );
    return memoizedValue;
}
