import Iconify from 'src/components/iconify/Iconify';



// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/#Home',

  },
  {
    title: 'Benefits',
    icon: <Iconify icon="ic:outline-touch-app" />,
    path: '/#Benefits',
  },
  // {
  //   title: "Pricing",
  //   icon: <Iconify icon="icomoon-free:price-tags" />,
  //   path: '/#Pricing',
  // },
  {
    title: 'About us',
    icon: <Iconify icon="material-symbols:info" />,
    path: '/#About_us',
  },
  {
    title: 'Contact us',
    icon: <Iconify icon="mdi:contact" />,
    path: '/#Contact_us',
  },
  // {
  //   title: 'Pages',
  //   path: '/pages',
  //   icon: <Iconify icon="solar:file-bold-duotone" />,
  //   children: [
  //     {
  //       subheader: 'Other',
  //       items: [
  //         { title: 'About us', path: "/" },

  //       ],
  //     },
  //     {
  //       subheader: 'Concepts',
  //       items: [
  //         { title: 'Shop', path: "/" },

  //       ],
  //     },
  //   ],
  // }
];
