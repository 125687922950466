import axios, { AxiosRequestConfig } from 'axios';


const HOST_API = process.env.REACT_APP_HOST;

const axiosInstance = axios.create({ baseURL: HOST_API });


axiosInstance.interceptors.response.use(
    (res) => res,
    (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
    const [url, config] = Array.isArray(args) ? args : [args];

    const res = await axiosInstance.get(url, { ...config });

    return res.data;
};





export const endpoints = {
    auth: {
        register: '/auth/register',
        login: '/auth/login',
        verify: (email: string) => `/auth/verify?email=${email}`,
        check: (token: string) => `/auth/check/${token}`,
        resend: (email: string) => `/auth/send/${email}`,
        forgetPWD: (email: string) => `/auth/forget?email=${email}`,
        reset: `/auth/resetPassword`,
        changePassword: `/auth/changePassword`
    },
    admin: {
        login: '/auth/adminLogin',
        verify: (email: string) => `/auth/admin/verify?email=${email}`,
        check: (token: string) => `/auth/admin/check/${token}`,
        resend: (email: string) => `/auth/admin/send/${email}`
    },
    dashboard: {
        admin: {
            my_info: '/auth/google/login',
            titles: {
                get: '/admin/Business_Title',
                getOne: (id: string) => `/admin/getOne/${id}`,
                post: `/admin/add_business_title`,
                delete: (id: string) => `/admin/delete_business_title/${id}`,
                put: (id: string) => `/admin/edit_business_title/${id}`
            },
            template: {
                upload: `/templates/upload`,
                update: (id: string) => `/templates/update/${id}`,
                getOne: (id: string) => `/templates/getOne/${id}`,
                getFirst: `/templates/getFirst`,
                delete: (id: string) => `/templates/delete_template/${id}`,
            },
            businessCard: {
                businessCustomerCards: (pageIndex: number, pageSize: number) => `/business/getBusinessCustomerCards?pageIndex=${pageIndex}&pageSize=${pageSize}`,
            },
            customers: {
                getAll: (pageIndex: number, pageSize: number) => `/customers/getAll?pageIndex=${pageIndex}&pageSize=${pageSize}`,
                getOne: (id: string) => `/customers/getOne/${id}`
            }

        },
        customer: {
            template: {
                getAll: (pageIndex: number, pageSize: number) => `/templates/getAll?pageIndex=${pageIndex}&pageSize=${pageSize}`,
                getMine: `/templates/getMine`
            },
            business: {
                create: `/business/create`,
                getBusinessCard: (id: string) => `/business/getBusinessCard/${id}`,
                getBusinessCardDuringEdit: (id: string, templateId: string) => `/business/getBusinessCardDuringEdit/${id}/${templateId}`,
                getBusinessCardByCardName: (cardName: string) => `/business/getBusinessCardByCardName/${cardName}`,
                getBusinessCardByTemplateId: (templateId: string, customerId: string) => `/business/getBusinessCardByTemplateId/${templateId}/${customerId}`,
                edit: (id: string) => `/business/edit/${id}`,
                social: `/business/socials`,
                link: {
                    add: `/business/links`,
                    delete: (templateId: string, customerId: string, link: string, title: string) => `/business/links/${templateId}/${customerId}?link=${link}&title=${title}`
                },
                delete: (id: string) => `/business/delete/${id}`,
                publish: (cardName: string) => `/business/publish/${cardName}`,
                isPublished: (cardName: string) => `/business/isPublished/${cardName}`,
                searchCardName: (search: string, id: string) => `/business/searchCardName?search=${search}&id=${id}`,
            },
            email: {
                send: '/email/send',
            },
            plan: {
                maxCards: '/business/maxCards'
            },
            account: {
                getMyInfos: `/customers/getMyInfos`,
                editMyInfos: `/customers/editMyInfos`
            }
        }
    },
};
