import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { ConfirmDialogProps } from 'src/types/dialog';
import { useTranslate } from 'src/locales';
import { CircularProgress } from '@mui/material';



export default function ConfirmDialog({
    title,
    content,
    cancel = true,
    maxWidth = 'sm',
    open,
    onClose,
    loading = false,
    ...other
}: ConfirmDialogProps) {
    const { t } = useTranslate()
    return (
        <>
            <Dialog fullWidth maxWidth={loading ? 'sm' : maxWidth} open={open} onClose={onClose} {...other}>
                {
                    title &&
                    <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>
                }
                {
                    loading &&
                    <CircularProgress
                        color='primary'
                        size={45}
                        sx={{ display: 'block', margin: '20px auto' }}
                    />
                }

                {!loading && content && <DialogContent sx={{ typography: 'body1' }}>
                    {content}
                    {
                        cancel &&
                        <Button
                            variant="outlined"
                            size='large'
                            sx={{display: 'block', ml: 'auto', color: 'text.secondary', borderColor: 'text.secondary' }}
                            onClick={onClose}
                        >
                            {t('common.cancel')}
                        </Button>
                    }
                </DialogContent>
                }

            </Dialog>
        </>
    );
}
