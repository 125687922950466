import { useCallback, useEffect, useMemo, useReducer } from "react";
import { CustomFile, DigitalTypes, ICardItem, ICardSocials, LinksProfileProps, tempPreviewStateType } from "src/types/temps";
import { ActionMapType } from "src/types/auth";
import { TempPreviewContext } from "./digital-preview-context";
import axiosInstance, { endpoints } from "src/utils/axios";
import { useSearchParams } from "src/hooks/use-search-params";

export enum PreviewPayload {
    INITIAL = 'INITIAL',
    INFO = 'INFO',
    FIRSTID = 'FIRSTID',
    SOCIALS = 'SOCIALS',
    LINKS = 'LINKS',
    EDITTEMPID = 'EDITTEMPID',
    ISEMPTYSOCIALS = 'ISEMPTYSOCIALS',
    ISEMPTYLINKS = 'ISEMPTYLINKS',
    ACTIVESTEP = 'ACTIVESTEP',
    SECTIONTYPE = 'SECTIONTYPE'
}


export type Payload = {
    [PreviewPayload.INFO]: ICardItem,
    [PreviewPayload.FIRSTID]: string,
    [PreviewPayload.INITIAL]: ICardItem,
    [PreviewPayload.SOCIALS]: ICardSocials,
    [PreviewPayload.LINKS]: LinksProfileProps,
    [PreviewPayload.EDITTEMPID]: string,
    [PreviewPayload.ISEMPTYSOCIALS]: boolean,
    [PreviewPayload.ISEMPTYLINKS]: boolean,
    [PreviewPayload.ACTIVESTEP]: number,
    [PreviewPayload.SECTIONTYPE]: DigitalTypes

};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const reducer = (state: tempPreviewStateType, action: ActionsType) => {

    if (action.type === PreviewPayload.INITIAL) {
        return {
            ...state,
            infos: action.payload
        }
    }
    if (action.type === PreviewPayload.INFO) {
        return {
            ...state,
            infos: {
                ...state.infos,
                ...action.payload
            }
        }
    }
    if (action.type === PreviewPayload.FIRSTID) {
        return {
            ...state,
            id: action.payload
        }
    }
    if (action.type === PreviewPayload.SOCIALS) {
        return {
            ...state,
            socials: action.payload
        }
    }
    if (action.type === PreviewPayload.LINKS) {
        return {
            ...state,
            links: action.payload
        }
    }
    if (action.type === PreviewPayload.EDITTEMPID) {
        return {
            ...state,
            editTempId: action.payload
        }
    }
    if (action.type === PreviewPayload.ISEMPTYSOCIALS) {
        return {
            ...state,
            isEmptySocials: action.payload
        }
    }
    if (action.type === PreviewPayload.ISEMPTYLINKS) {
        return {
            ...state,
            isEmptyLinks: action.payload
        }
    }
    if (action.type === PreviewPayload.ACTIVESTEP) {
        return {
            ...state,
            activeStep: action.payload,
        }
    }
    if (action.type === PreviewPayload.SECTIONTYPE) {
        return {
            ...state,
            sectionType: action.payload
        }
    }

    return state;
};

type Props = {
    children: React.ReactNode;
};

const initial = {
    infos: {} as ICardItem,
    socials: {} as ICardSocials,
    isEmptySocials: true,
    links: [] as LinksProfileProps,
    isEmptyLinks: true,
    id: '',
    editTempId: '',
    activeStep: 0,
    sectionType: DigitalTypes.CARD
}

// the digital preview provider is a context that will be used to store all the data entered by the user in the digital preview
export default function DigitalPreviewProvider({ children }: Props) {
    const [state, dispatch] = useReducer(reducer, initial);

    /*
      this callback function is created to create or edit a business card ,
      as you can see in the parameters ,businessCardId is optional, in mode create it will be empty
      and in mode edit it will be the id of the business card you want to edit,
      thats why i store a state called editTempId(businessCardId).
    */
    const createBusinessCard = useCallback(
        async (businessCardData: ICardItem & { customerId: string; templateId: string, bussinessCardId?: string }) => {
            const formData = new FormData();
            Object.entries(businessCardData).forEach(([key, value]) => {
                if (['logo', 'logoHub', 'photoProfile'].includes(key) && isFile(value)) {
                    formData.append(key, value);
                } else {
                    formData.append(key, typeof value === 'string' ? value : JSON.stringify(value));
                }
            })
            const URL = `${endpoints.dashboard.customer.business.create}`
            const res = await axiosInstance.post(URL, formData);

            dispatch({
                type: PreviewPayload.EDITTEMPID,
                payload: res.data || ''
            })
        }, [])


    // isFile is a function to check if the value is a file or not
    function isFile(value: any): value is File {
        return value instanceof File;
    }

    // this callback function is created to add socials links to a business card
    const addSocialsLinks = useCallback(
        async (socials: ICardSocials, customerId: string, templateId: string, bussinessCardId: string = '') => {
            if (!customerId || !templateId) {
                throw new Error('credentials are missing')
            }
            const URL = `${endpoints.dashboard.customer.business.social}`
            const res = await axiosInstance.post(URL, {
                socials: JSON.stringify(socials),
                customerId,
                templateId,
                bussinessCardId
            })
            dispatch({
                type: PreviewPayload.EDITTEMPID,
                payload: res.data || ''
            })
        }, [])


    // this callback function is created to add links to a business card
    const addLinks = useCallback(
        async (links: LinksProfileProps, customerId: string, templateId: string, bussinessCardId: string = '', logoLink?: CustomFile | string) => {
            if (!customerId || !templateId) {
                throw new Error('credentials are missing')
            }
            const formData = new FormData();
            if (logoLink && isFile(logoLink)) {
                formData.append('logo', logoLink);
            }
            formData.append('links', JSON.stringify(links));
            formData.append('customerId', customerId);
            formData.append('templateId', templateId);
            formData.append('bussinessCardId', bussinessCardId);
            const URL = `${endpoints.dashboard.customer.business.link.add}`
            const res = await axiosInstance.post(URL, formData)
            dispatch({
                type: PreviewPayload.EDITTEMPID,
                payload: res.data || ''
            })
        }, [])


    // this is a memoized value that will be used in the context
    const memoizedValue = useMemo(
        () => ({
            infos: state.infos,
            sectionType: state.sectionType,
            cardId: state.id,
            editTempId: state.editTempId,
            socials: state.socials,
            links: state.links,
            isEmptySocials: state.isEmptySocials,
            isEmptyLinks: state.isEmptyLinks,
            activeStep: state.activeStep,
            dispatch,
            addSocialsLinks,
            addLinks,
            createBusinessCard
        }),
        [dispatch, state.infos, state.id, state.socials, state.links, state.editTempId, state.isEmptySocials, state.isEmptyLinks, state.activeStep, state.sectionType]
    );

    return <TempPreviewContext.Provider value={memoizedValue}>
        {children}
    </TempPreviewContext.Provider>;
}
