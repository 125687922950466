import { Navigate, Outlet, useRoutes } from "react-router-dom";
import { HomePage } from "./main";
import { authRoutes } from "./auth";
import { adminRoutes } from "./admin";
import { dashAdminRoutes } from "./dashboard";
import { customerRoutes } from "./customer";
import { Suspense, lazy } from "react";
import CompactLayout from "src/layouts/compact";
import SplashScreen from "src/components/loading-screen/SplashScreen";
import MainLayout from "src/layouts/main";
import HomeGuard from "src/guards/home-guard";

const DigitalHubPage = lazy(() => import('src/pages/digital-hub/digital-hub-page'));
const Page404Page = lazy(() => import('src/pages/Page404'));
const Page403Page = lazy(() => import('src/pages/Page403'));
const PolicyPage = lazy(() => import('src/pages/auth/policy-page'));
const TermsOfServicePage = lazy(() => import('src/pages/auth/terms-of-services-page'));

export default function Router() {
    return useRoutes([
        {
            path: '/',
            element: (
                <HomeGuard>
                    <MainLayout>
                        <HomePage />
                    </MainLayout>
                </HomeGuard>
            ),
            children: [

            ]
        },
        {
            path: 'HUB/:cardName',
            element: (
                <DigitalHubPage />
            )
        },
        ...authRoutes,
        ...adminRoutes,
        ...dashAdminRoutes,
        ...customerRoutes,
        {
            element: (
                <CompactLayout>
                    <Suspense fallback={<SplashScreen />}>
                        <Outlet />
                    </Suspense>
                </CompactLayout>
            ),
            children: [
                { path: '404', element: <Page404Page /> },
                { path: '403', element: <Page403Page /> }
            ],
        },
        {
            element: (
                <MainLayout>
                    <Outlet />
                </MainLayout>
            ),
            children: [
                { path: 'privacyPolicy', element: <PolicyPage /> },
                { path: 'termsOfService', element: <TermsOfServicePage /> },
            ],
        }
    ])

}