import { SxProps, Theme } from "@mui/material";
import { Dispatch } from "react";
import { PreviewPayload } from "src/context/digital-preview-provider";

export enum DigitalTypes {
    CARD = "CARD",
    INFO = "INFO",
    DIGITALHUB = "DIGITALHUB",
    SOCIAL = 'SOCIAL',
    LINKS = "LINKS",
    MENU = "MENU",
    LOCATION = "LOCATION",
    QRCODE = "QR-CODE"
}

export type tempsTypes = 'UPLOAD' | 'CARD-VERT' | 'CARD-HORIZ' | 'HUB-VERT' | 'HUB-HORIZ';

export type imagesTypes = 'HorizBImg' | 'HorizFImg' | 'vertBImg' | 'vertFImg'

export type TopBarAdminProps = {
    label: string,
    type: tempsTypes,
    icon: string,
    active?: boolean
    desc?: string,
    index?: number,
}

export type TopBarCustomerProps = Omit<TopBarAdminProps, 'type' | 'disabled'> & {
    type: DigitalTypes,
    disabled?: boolean
}

// export interface digitalConfigProp {
//     label: string,
//     type: DigitalTypes,
//     icon: string,
//     desc?: string,
//     index?: number,
//     disabled?: boolean
// }

export interface CustomFile extends File {
    path?: string;
    preview?: string;
    lastModifiedDate?: Date;
}
export interface tempsUploadProps {
    HorizBImg?: CustomFile | string,
    HorizFImg?: CustomFile | string,
    vertBImg?: CustomFile | string,
    vertFImg?: CustomFile | string,
    logo?: CustomFile | string
}

export interface UploadProps {
    label: string,
    name: imagesTypes,
    icon: string,
    sx?: SxProps<Theme>,
    file?: CustomFile | string | null;
    onUpload?: (event: React.ChangeEvent<HTMLInputElement>, type: string) => void
}

export type cssProps = {
    qrCodeColor?: string;
    logoColor?: string;
    fontColor?: string;
    fontFamily?: string;
    fontSize?: number;
    qrCodeSize?: number
}

export type CardPositions = {
    name: string;
    label: string;
    placeHolder: string;
    icon: string;
}

export type ActionsTempType = {
    type: tempsTypes,
    payload: Record<string, any>
};

export type PositionsProps = {
    qrCodeX?: number;
    qrCodeY?: number;
    fullNameX?: number;
    fullNameY?: number;
    titleX?: number;
    titleY?: number;
    logoX?: number;
    logoY?: number;
    contactX?: number;
    contactY?: number;
}

export type CardPreviewProps = cssProps & tempsUploadProps & PositionsProps & { width?: string, ratio?: number }

export type templatesProps = {
    id: string;
    tempUploads?: tempsUploadProps;
    css?: cssProps;
    CHP?: PositionsProps;
    CVP?: PositionsProps;
    logo?: string;
}

export enum cardTypeProp {
    HORIZ = 'HORIZ',
    VERT = 'VERT'
}
export type TempStateType = {
    infos: templatesProps,
    cardType: cardTypeProp
};

export type TEMPContextType = {
    infos: templatesProps;
    cardType: cardTypeProp;
    dispatch: Dispatch<any>;
}

export enum CardType {
    BUSINESS = 'Business',
    PERSONEL = 'Personel',
}

export type ICardItem = {
    id?: string;
    cardType?: CardType;
    holderName?: string;
    cardName?: string;
    companyName?: string;
    email?: string;
    location?: string;
    // city?: string;
    // country?: string;
    phone?: string;
    website?: string;
    businessTitle?: string;
    logo?: string;
    bio?: string;
    logoHub?: string;
    photoProfile?: string;
};

export type ICardSocials = {
    [key: string]: any;
}

type cardInfo = {
    infos?: ICardItem;
}


export type CardEditProps = CardPreviewProps & cardInfo & {
    cardId?: string;
    displayPlaceholder?: boolean;
    setImageRef?: (ref: React.RefObject<SVGSVGElement> | null) => void;
}



export type TEMPPreviewContextType = {
    infos: ICardItem;
    socials: ICardSocials;
    isEmptySocials: boolean;
    links: LinksProfileProps;
    isEmptyLinks: boolean;
    cardId: string;
    editTempId: string;
    activeStep: number;
    sectionType: DigitalTypes;
    Loading?: boolean;
    dispatch: Dispatch<any>;
    createBusinessCard?: (data: ICardItem & { customerId: string, templateId: string, bussinessCardId?: string }) => void,
    addSocialsLinks?: (data: ICardSocials, customerId: string, templateId: string, bussinessCardId?: string) => void,
    addLinks?: (data: LinksProfileProps, customerId: string, templateId: string, bussinessCardId?: string, logoLink?: CustomFile | string,) => void,
}

export type tempPreviewStateType = {
    id: string,
    editTempId: string,
    infos: ICardItem,
    socials: ICardSocials,
    isEmptySocials: boolean,
    links: LinksProfileProps,
    isEmptyLinks: boolean,
    activeStep: number,
    sectionType: DigitalTypes
}


export type FirebaseTemplatesProps = {
    id: string;
    images: string[]
}[]


// links 
export type LinksProfileProps = {
    id?: string;
    url: string;
    name: string;
    logo?: CustomFile | string;
}[]

// my card Info

export type IMCardsItems = ICardItem & {
    templateId: string;
    customerId: string;
    template: Record<string, any>;
    socials: ICardSocials;
    links: LinksProfileProps;
}